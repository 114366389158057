import { Provider, useSelector } from "react-redux";
import { useAuth } from "../hooks/auth";
//import { Authenticated } from "../components/templates/Authenticated/Authenticated";
import { useRouter } from "next/router";
import { RootState } from "../redux/store";
import { Grid, CircularProgress } from "@mui/material";
import Dashboard from "./dashboard";
import Authenticated from "../components/templates/Authenticated/AppLayout";
import { useTranslation } from "react-i18next";

export default function Home() {
	const {t}=useTranslation();
	const router = useRouter();
	// const { authenticated} = useSelector(
	//   (state: RootState) => state.auth
	// );

	// if(authenticated === false) {
	//   router.push('/login');
	// }

	//if(authenticated === true) {
	// return (
	// 	<>
	// 		{/* <Dashboard /> */}
	// 		test
	// 	</>
	// );
	//}

	// return (
	// 	<>
	// 		<Grid
	// 			container
	// 			spacing={0}
	// 			direction="column"
	// 			alignItems="center"
	// 			justifyContent="center"
	// 			style={{ minHeight: "100vh" }}
	// 		>
	// 			<Grid item xs={3}>
	// 				<CircularProgress />
	// 			</Grid>
	// 		</Grid>
	// 	</>
	// );

	return (
		<>
			<Authenticated>{t("Test")}</Authenticated>
		</>
	);
}
